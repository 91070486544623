export const mainContainer = {
    padding: 0, 
    alignItems: "center"
}

export const logoBox = {
    textAlign: "center", 
    padding: "50px 50px 30px"
}

export const mainCard = { 
    overflow: "visible",
    position: "relative",
    maxWidth: 600,
    margin: "0 auto",
    borderRadius: 5,
    backgroundColor: "#F5EEE5",
    boxShadow: "0 0.4px 3.6px rgba(0, 0, 0, 0.004), 0 1px 8.5px rgba(0, 0, 0, 0.01), 0 1.9px 15.7px rgba(0, 0, 0, 0.019), 0 3.4px 28.2px rgba(0, 0, 0, 0.03), 0 6.3px 54.4px rgba(0, 0, 0, 0.047), 0 15px 137px rgba(0, 0, 0, 0.07)" 
}

export const nestedCard = {
    maxWidth: 600,
    margin: "0 auto",
    borderRadius: 5,
    backgroundColor: "#1D3805",
    color: "#F5EEE5",
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
}

export const notFoundCard = { 
    overflow: "visible",
    position: "relative",
    height: "calc(100vh - 243.5px)",
    maxWidth: 600,
    margin: "0 auto",
    borderRadius: 5,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: "#F5EEE5",
    boxShadow: "0 0.4px 3.6px rgba(0, 0, 0, 0.004), 0 1px 8.5px rgba(0, 0, 0, 0.01), 0 1.9px 15.7px rgba(0, 0, 0, 0.019), 0 3.4px 28.2px rgba(0, 0, 0, 0.03), 0 6.3px 54.4px rgba(0, 0, 0, 0.047), 0 15px 137px rgba(0, 0, 0, 0.07)" 
}

export const stepsHeading = {
    position: "relative", 
    top: "-35px", 
    left: "50%",
    display: "inline-block", 
    fontWeight: "900", 
    width: "auto", 
    textAlign: "center", 
    color: "#1D3805",
    transform: "translateX(-50%)",
    zIndex: 2
}

export const stepsSubheading = {
    position: "absolute",
    top: "100%",
    right: 0,
    fontWeight: 600,
}

export const countryCodeAvatar = {
    position: 'absolute',
    top: '0',
    left: '0',
    paddingTop: '5px',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: "#1d3805",
    fontWeight: "600"
}