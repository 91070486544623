import { Outlet, useRoutes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import AnimalPage from "./pages/AnimalPage";
import NotFound from "./pages/NotFoundPage";
import Box from '@mui/material/Box';
import "./index.css";

const MenuLayout = () => (
  <Outlet />
);

const routesConfig = [
  {
    element: <MenuLayout />,
    children: [
      {
        path: "/",
        element: <HomePage />
      },
      {
        path: "/:animalId",
        element: <AnimalPage />
      },
      {
        path: "/not-found",
        element: <NotFound />
      }
    ]
  },
];

export default function App() {
  const routes = useRoutes(routesConfig);

  return (
    <Box component="main" height="100vh">
      {routes}
    </Box>
  );
}