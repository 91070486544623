import React, { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import Moment from 'react-moment';
import { Container, Box, Card, CardMedia, CardContent, List, ListItem, ListItemAvatar, ListItemText, Avatar, Typography } from '@mui/material';
import logoImage from '../assets/images/logo.svg';
import icon1 from '../assets/images/Rectangle 17 (Stroke).svg';
import grass from '../assets/images/GrassOutlined.svg';
import icon2 from '../assets/images/icon.svg';
import location1 from '../assets/images/Component 1.svg';
import location2 from '../assets/images/Component 3.svg';
import flag from '../assets/images/flag-400 1.png';
import { mainContainer, logoBox, mainCard, nestedCard, stepsHeading, stepsSubheading, countryCodeAvatar } from '../assets/styles'
import { numberWithCommas } from '../services/utils/numberFormat';
import AnimalMovement from "../components/AnimalMovement";
import Locations from "../components/Locations";

const AnimalPage = () => {
  const { animalId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const staticData = {
    steps: "Schritte auf der Weide",
    tierNumber: "GPS getracktes Tier Nr.",
    number: "Ohrmarke",
    birthday: " GEB ",
  }
  const [steps, setSteps] = useState("");

  function animateSteps(start, end, duration) {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      // if (obj) obj.innerHTML = numberWithCommas(Math.floor(progress * (end - start) + start));
      setSteps(numberWithCommas(Math.floor(progress * (end - start) + start)));
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }

  useEffect(() => {
    fetch(`https://office.millionsteps.earth/api/v1/getInfoEarmark?earmark=${animalId}`, {})
      .then((response) => {
        if(response.status === 200) {
          return response.json();
        } else {
          window.location.replace("/not-found")
        }
      })
      .then((data) => {
        setData(data);
        setIsLoading(false);
        animateSteps(0, data.steps, 5000);
      })
      .catch((error) => console.log(error));
  }, [animalId]);

  return (
    <>
      {!isLoading && (
        <Container maxWidth="sm" sx={mainContainer}>
          <Box sx={logoBox}>
            <img height="160px" width="200px" alt="logo" src={logoImage} />
          </Box>
          <Card sx={mainCard}>
            <Typography variant="h2" component="div" sx={stepsHeading}>
              <span id="steps-animation">
                {steps ? steps : numberWithCommas((data.steps).toFixed(0))}
              </span>
              <Typography component="span" sx={stepsSubheading}>
                {staticData.steps}
              </Typography>
            </Typography>
            <Box className="map-wrapper" sx={{height: "300px" }}>
              <AnimalMovement />
            </Box>
            <CardContent>
              <List sx={{ width: '100%' }}>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar sx={{ position: 'relative' }}>
                    <Avatar alt="" src={icon1} sx={{ borderRadius: 0 }} />
                    <Typography component="span" sx={countryCodeAvatar}>
                      {data.Earmark.replace(/[^a-z]/gi, '')}
                    </Typography>
                  </ListItemAvatar>
                  <ListItemText 
                    primary={
                      <Typography component="span" variant="body1" sx={{ display: 'block', textTransform: "uppercase", color: "#1D3805", fontWeight: "800" }}>
                        {staticData.tierNumber}
                      </Typography>
                    }
                    secondary={
                      <Typography component="span" variant="body2" sx={{ display: 'inline', textTransform: "uppercase" }}>
                        {data.Earmark}{" ("}{staticData.number}{")"}
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar >
                    <Avatar alt="" src={icon2} sx={{ borderRadius: 0 }} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography component="span" variant="body1" sx={{ display: 'block', textTransform: "uppercase", color: "#1D3805", fontWeight: "800" }}>
                        {data.Race}
                      </Typography>
                    }
                    secondary={
                      <Typography component="span" variant="body2" sx={{ display: 'inline', textTransform: "uppercase" }}>
                        {data.gender}{", "}
                        <Moment format="MM/YYYY">{data.birthDay}</Moment>{data.butcherDay ? (<Fragment> - <Moment format="MM/YYYY">{data.butcherDay}</Moment></Fragment>) : null}
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </CardContent>
            <Card sx={nestedCard}>
            <CardContent>
              <List sx={{ width: 'calc(100% - 41px)', display: "inline-block" }}>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar >
                    <Avatar alt="" src={grass} sx={{ borderRadius: 0 }} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                        <Typography
                          sx={{ display: 'block', textTransform: "uppercase", fontWeight: 600 }}
                          component="span"
                          color="#78FF00"
                        >
                          {data.nameFarmer}
                        </Typography>
                    }
                    secondary={
                        <Typography
                          sx={{ display: 'inline', textTransform: "uppercase" }}
                          component="span"
                          color="#F5EEE5"
                        >
                          {data.region}
                        </Typography>
                    }
                  />
                </ListItem>
              </List>
              <Avatar alt="" sx={{ display: "inline-block", height: "30px" }} src={flag} variant="rounded" />
              <CardMedia component="img" sx={{}} image={data.pictureFarmer} />
              <List sx={{ width: '100%' }}>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar >
                    <Avatar alt="" src={location1} sx={{ borderRadius: 0, width: "30px" }} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                        <Typography
                          sx={{ display: 'block', textTransform: "uppercase", fontWeight: 600 }}
                          component="span"
                          color="#78FF00"
                        >
                          Standort Landwirt
                        </Typography>
                    }
                    secondary={
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="#F5EEE5"
                        >
                          {data.nameAdressFarmer}
                        </Typography>
                    }
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar >
                    <Avatar alt="" src={location2} sx={{ borderRadius: 0, width: "30px" }} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                        <Typography
                          sx={{ display: 'block', textTransform: "uppercase", fontWeight: 600 }}
                          component="span"
                          color="#FF8A00"
                        >
                          Standort  Schlachtbetrieb
                        </Typography>
                    }
                    secondary={
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="#F5EEE5"
                        >
                          {data["nameAdress Butcher"]}
                        </Typography>
                    }
                  />
                </ListItem>
              </List>
              </CardContent>
              <Box sx={{height: "250px" }}>
                <Locations data={data} />
              </Box>
              <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {data.nameFarmer}
              </Typography>
              <Typography variant="body2">
                {data.aboutFarmer}
              </Typography>
            </CardContent>
          </Card>
          </Card>
        </Container>
      )}
    </>
  );
};

export default AnimalPage;
