import React from "react";
import { Container, Box, Card, CardContent, Typography } from '@mui/material';
import logoImage from '../assets/images/logo.svg';
import { mainContainer, logoBox, notFoundCard, stepsHeading, stepsSubheading } from '../assets/styles'

const NotFoundPage = () => {
  return (
    <>
        <Container maxWidth="sm" sx={mainContainer}>
          <Box sx={logoBox}>
            <img height="160px" width="200px" alt="logo" src={logoImage} />
          </Box>
          <Card sx={notFoundCard}>
            <Typography variant="h2" component="div" sx={stepsHeading}>
              Sorry
              <Typography component="span" sx={stepsSubheading}>
                Something went wrong
              </Typography>
            </Typography>
            <CardContent>
            </CardContent>
          </Card>
        </Container>
    </>
  );
};

export default NotFoundPage;
