import * as React from 'react';
import { useParams } from "react-router-dom";
import {useRef, useState, useEffect} from 'react';
import {Map, Source, Layer } from 'react-map-gl';
import * as turf from "@turf/turf";
import 'mapbox-gl/dist/mapbox-gl.css';

const AnimalMovement = () => {
  const { animalId } = useParams();
  const [data, setData] = useState([]);
  const [pointData, setPointData] = useState(null);
  const [traceData, setTraceData] = useState(null);
  const [coordinates, setCoordinates] = useState([]);
  const [index, setIndex] = useState(0);

  const mapRef = useRef();

  const MAPBOX_TOKEN = 'pk.eyJ1IjoiZmluYmMiLCJhIjoiY2w4eTk3cDk5MGQwczNwbnVmenp2aGE5dSJ9.zcrF1QtDNiQd8-ikBFN_Ow';
  const mapStyle = "mapbox://styles/mapbox/satellite-v9";
  const zoomLevel = 15;
  const initialViewState = {
    longitude: 10.4478313,
    latitude: 51.1638175,
    zoom: 4,
    bearing: 0,
    pitch: 0
  }
  const pointLayer = {
    id: 'point',
    type: 'circle',
    paint: {
      'circle-radius': 6,
      'circle-color': '#1D3805'
    }
  };
  const traceLayer = {
    'id': 'trace',
    'type': 'line',
    'source': 'trace',
    'paint': {
      'line-color': '#78FF00',
      'line-opacity': 1,
      'line-width': 2,
      'line-dasharray': [1, 0.5],
      'line-blur': 0,
      'line-gap-width': 0,
    },
    'line-cap': 'round'
    // id: 'trace', // in order to use this you'll need to add "lineMetrics" to the <Source id='trace' lineMetrics ...   
    // type: 'line',
    // source: 'line',
    // paint: {
    //   'line-color': '#78FF00',
    //   'line-width': 4,
    //   'line-gradient': [
    //     'interpolate',
    //     ['linear'],
    //     ['line-progress'],
    //     0,
    //     'transparent',
    //     0.9,
    //     'transparent',
    //     1,
    //     '#78FF00'
    //   ]
    // },
    // layout: {
    //   'line-cap': 'round',
    //   'line-join': 'round'
    // }
  };

  function chunk(array, size) {
    const chunkedArray = [];

    function arrayEquals(a, b) {
      return a.length === b.length && a.every((val, index) => val === b[index]);
    }

    for (let i = 0; i < array.length; i++) {
        const last = chunkedArray[chunkedArray.length - 1];
        if (!last || last.length === size) {
            chunkedArray.push([array[i]]);
        } else {
            last.push(array[i]);
        }
        if(chunkedArray.length > 2 && arrayEquals(chunkedArray[chunkedArray.length - 1], chunkedArray[chunkedArray.length - 2])) {
          chunkedArray.pop();
        }
    }
    return chunkedArray;
  }

  function point(index) {
    if (index < data.length) {
      mapRef.current?.flyTo({center: data[index], zoom: zoomLevel, duration: 1000});

      return {
        type: 'Point',
        coordinates: data[index]
      };
    }
  }

  function movePoint(index) {
    if (index < data.length) {
      setCoordinates(current => [...current, data[index]])
    }
    return {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: 'LineString',
            coordinates: coordinates
          }
        }
      ]
    };
  }

  useEffect(() => {
    fetch(`https://office.millionsteps.earth/api/v1/getCoordinates?earmark=${animalId}&timeframeMonths=12`, {})
      .then((response) => {
        if(!response.ok) {
          throw new Error("Unable to get coordinates!");
        }
        return response.json();
      })
      .then((data) => {
        const chunkedArray = chunk(data.coordinates, 2);
        const line = turf.lineString(chunkedArray.reverse());
        const bezierCurve = turf.bezierSpline(
          line, 
          {
            resolution: data.coordinates.length / 100 * 200000, 
            sharpness: 0.85
          }); // https://turfjs.org/docs/#bezierSpline
        const positions = bezierCurve.geometry.coordinates;
        setData(positions);
      })
      .catch((error) => {
        throw new Error("Unable to process coordinates!");
      });
  }, [animalId]);

  useEffect(() => {
    if(data.length > 0) {
      const animation = window.requestAnimationFrame(() => {
        setIndex(index + 1);
        setTraceData(movePoint(index))
        setPointData(point(index))
      });
      return () => window.cancelAnimationFrame(animation);
    }
  });
  
  return (
    <Map ref={mapRef} mapboxAccessToken={MAPBOX_TOKEN} mapStyle={mapStyle} initialViewState={initialViewState}>
      {traceData && (
        <Source id='trace' type="geojson" data={traceData}>
          <Layer {...traceLayer} />
        </Source>
      )}
      {pointData && (
        <Source type="geojson" data={pointData}>
          <Layer {...pointLayer} />
        </Source>
      )}
    </Map>
  );
}

export default AnimalMovement;