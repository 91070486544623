import React, {useEffect, useState, useRef} from "react";
import { Map, Marker } from 'react-map-gl';
import location4 from '../assets/images/Component 4.svg';
// import location5 from '../assets/images/Component 5.svg';

const Locations = (props) => {
  const [farmerCoordinates, setFarmerCoordinates] = useState([]);
  const [marker, setMarker] = useState(null);
  
  const mapRef = useRef();

  const MAPBOX_TOKEN = 'pk.eyJ1IjoiZmluYmMiLCJhIjoiY2w4eTk3cDk5MGQwczNwbnVmenp2aGE5dSJ9.zcrF1QtDNiQd8-ikBFN_Ow';
  const mapStyle = "mapbox://styles/mapbox/outdoors-v11";
  const initialViewState = {
    longitude: 10.4478313,
    latitude: 51.1638175,
    zoom: 4,
    bearing: 0,
    pitch: 0
  }
  
  useEffect(() => {
    if ("nameAdressFarmer" in props.data) {
      fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${props.data.nameAdressFarmer}.json?country=de&access_token=pk.eyJ1IjoiZmluYmMiLCJhIjoiY2w4eTk3cDk5MGQwczNwbnVmenp2aGE5dSJ9.zcrF1QtDNiQd8-ikBFN_Ow`, {})
        .then((res) => res.json())
        .then((response) => {
          setFarmerCoordinates(response.features[0].geometry.coordinates);
      })
      .catch((error) => console.log(error));
    }
    
  }, [props]);

  useEffect(() => {
    if(farmerCoordinates.length > 0) {
      mapRef.current?.flyTo({center: farmerCoordinates, zoom: 8, duration: 1000});
      setMarker({
        type: 'Point',
        coordinates: farmerCoordinates
      })
    }
  }, [farmerCoordinates]);

  return (
    <Map ref={mapRef} mapboxAccessToken={MAPBOX_TOKEN} mapStyle={mapStyle} initialViewState={initialViewState}>
    {marker && (
      <Marker longitude={marker.coordinates[0]} latitude={marker.coordinates[1]} anchor="bottom" >
        <img alt="" src={location4} />
      </Marker>
    )}
  </Map>
  );
};

export default Locations;