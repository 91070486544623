import React, { useEffect } from "react";
//import { Link } from "react-router-dom";

const HomePage = () => {
  // const [isLoading, setIsLoading] = useState(true);
  // const [data, setData] = useState();

  useEffect(() => {
    window.location.replace("https://www.millionsteps.earth/")
  },[])

  // useEffect(() => {
  //   fetch("https://millionsteps.dev2.saas.do/api/v1/getInfoEarmark?earmark=DE0124077917", {})
  //     .then((res) => res.json())
  //     .then((response) => {
  //       setData([response]);
  //       setIsLoading(false);
  //     })
  //     .catch((error) => console.log(error));
  // }, []);

  return (
    <>
      {/* {!isLoading &&
        data.map((animal) => {
          return (
            <h5 key={animal.Earmark}>
              <Link to={`/${animal.Earmark}`}>{animal.nameFarmer}'s Page</Link>
            </h5>
          );
        })} */}
    </>
  );
};

export default HomePage;